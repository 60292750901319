import { Button, Checkbox, Table, Tabs, Loader, Center } from '@mantine/core';
import { useEffect, useState } from 'react';
import Modal from './component/modal';
import { useDisclosure } from '@mantine/hooks';
import { apiApproval } from '../../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { FaSpinner } from 'react-icons/fa';
import Pagination from '../common/Pagination';
import { useApproval } from '../../../../context/approval.context';
import { useAccount } from '../../../../context/account.context';

const ApprovalsTab = () => {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [approval, setApproval] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [tab, setTab] = useState<string | null>('new');
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null);
  const [isApproval, setIsApproval] = useState<boolean>(true);
  // paging from the backend...
  const [paging, setPaging] = useState<Paging>({ next: null, pages: 0, previous: null, total: 0 });
  const [page, setPage] = useState(1);
  const { getApprovalCount } = useApproval();
  const { currentAccount } = useAccount();

  const getApproval = async (status: string) => {
    setLoading(true);
    setError(null);
    try {
      const resp = await apiApproval({ status, page });
      setApproval(resp.data.transactions);
      if (resp.data.paging) setPaging(resp.data.paging);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setError('Unable to get approvals');
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        message: error?.message || 'An error occurred, please try again later.',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tab) {
      // reset page and paging to defaults everytime tabs change
      setPage(1);
      setPaging({ next: null, pages: 0, previous: null, total: 0 });
      getApproval(tab);
      if (tab === 'new') {
        getApprovalCount();
      }
    }
  }, [tab]);

  useEffect(() => {
    if (tab) {
      getApproval(tab);
    }
  }, [page, currentAccount]);

  const resetSteps = () => {
    setSelectedRows([]);
  };

  const handleApproveClick = () => {
    if (selectedRows.length > 0) {
      setIsApproval(true);
      open();
    }
  };

  const handleRejectClick = () => {
    if (selectedRows.length > 0) {
      setIsApproval(false);
      open();
    }
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedRows(approval.map((app) => app._id));
    } else {
      setSelectedRows([]);
    }
  };

  const handleCheckboxChange = (transactionId: string, checked: boolean) => {
    setSelectedRows((prevSelectedRows) =>
      checked
        ? [...prevSelectedRows, transactionId]
        : prevSelectedRows.filter((id) => id !== transactionId)
    );
  };

  const handleRowClick = (transactionId: string) => {
    if (selectedRows.length === 0) {
      const selected = approval.find((transaction) => transaction._id === transactionId);
      setSelectedTransaction(selected as Transaction);
      open();
    }
  };

  const handleClose = () => {
    // setSelectedRows([]);
    // setSelectedTransaction(null);
    close();
  };

  const roleName = localStorage.getItem('roleName') as RoleName | null;

  const renderTable = () => {
    if (loading) {
      return (
        <Center>
          <Loader size={30} className="my-24" />
        </Center>
      );
    }

    if (approval.length === 0) {
      return (
        <div className="text-center">
          <img
            src="/assets/dash/empty-state.png"
            alt="empty state"
            className="w-[17rem] mx-auto my-10"
          />
          <h2 className="font-semibold mt-1">No Data</h2>
          <p className="text-sm">Currently, there are no {tab} payments recorded.</p>
        </div>
      );
    }

    const rows = approval.map((transaction, index) => {
      // transaction verified by the checker but awaiting authoriser approval...
      const checkerPendingAuthoriser =
        roleName === 'checker' && transaction.approvalStatus === 'awaiting_authoriser';

      const initiatorPendingApproval =
        roleName === 'initiator' &&
        (transaction.approvalStatus === 'awaiting_authoriser' ||
          transaction.approvalStatus === 'awaiting_authorization');

      return (
        <Table.Tr
          key={transaction._id}
          onClick={() => {
            if (initiatorPendingApproval || checkerPendingAuthoriser) {
              notifications.show({
                title: `Transaction is awaiting ${
                  transaction.approvalStatus === 'awaiting_authorization'
                    ? "checker's "
                    : "authoriser's "
                } approval`,
                color: 'blue',
                message: '',
                autoClose: 1500,
              });
            }
            handleRowClick(transaction._id);
          }}
          className="cursor-pointer">
          <Table.Td>
            {checkerPendingAuthoriser || initiatorPendingApproval ? (
              <div>
                <FaSpinner className="w-4 h-4 animate-spin" title="awating approval" />
              </div>
            ) : (
              <Checkbox
                aria-label="Select row"
                disabled={tab !== 'new'}
                className="cursor-pointer"
                checked={selectedRows.includes(transaction._id)}
                onClick={(event) => {
                  event.stopPropagation();
                  handleCheckboxChange(transaction._id, event.currentTarget.checked);
                }}
              />
            )}
          </Table.Td>
          <Table.Td>{index + 1}</Table.Td>
          <Table.Td>
            {transaction.createdAt
              ? dayjs(transaction.createdAt).format('ddd, MMM DD YYYY hh:mm A')
              : 'NA'}
          </Table.Td>
          <Table.Td>{numeral(transaction?.amount || 0).format('0,0.00')}</Table.Td>
          <Table.Td>{transaction.narration}</Table.Td>
          <Table.Td>{transaction.type}</Table.Td>
          <Table.Td>{numeral(transaction.balance).format('0,0.00')}</Table.Td>
        </Table.Tr>
      );
    });

    return (
      <Table striped highlightOnHover withRowBorders={false} stickyHeader stickyHeaderOffset={0}>
        <Table.Thead>
          <Table.Tr className="bg-[#F8F8F8]">
            <Table.Th w={10}>
              <div>
                <Checkbox
                  aria-label="Select all"
                  label="All"
                  disabled={tab !== 'new'}
                  checked={approval.length === selectedRows.length}
                  className={`cursor-pointer font-semibold text-xs ${
                    tab !== 'new' ? 'hidden' : ''
                  }`}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleSelectAll(event.currentTarget.checked);
                  }}
                />
              </div>
            </Table.Th>
            <Table.Th>S/N</Table.Th>
            <Table.Th>Date</Table.Th>
            <Table.Th>Amount(₦)</Table.Th>
            <Table.Th>Description</Table.Th>
            <Table.Th>Type</Table.Th>
            <Table.Th>Balance</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    );
  };

  return (
    <>
      <div className="mt-6 mb-10 flex justify-between">
        <h1 className="font-medium my-auto">My Approvals</h1>
        <div className="my-auto">
          {roleName === 'initiator' ? null : (
            <div className="my-auto flex gap-4">
              <Button
                variant="outline"
                w={120}
                radius={6}
                type="button"
                onClick={handleRejectClick}
                disabled={selectedRows.length === 0}>
                Decline
              </Button>

              <Button
                variant="gradient"
                gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
                w={120}
                radius={6}
                type="button"
                onClick={handleApproveClick}
                disabled={selectedRows.length === 0}>
                Approve
              </Button>
            </div>
          )}
        </div>
      </div>
      <Tabs
        defaultValue="new"
        value={tab}
        mt={10}
        className="bg-[#ffffff] rounded-xl p-3"
        onChange={setTab}>
        <Tabs.List>
          <Tabs.Tab value="new">New</Tabs.Tab>
          <Tabs.Tab value="approved">Approved</Tabs.Tab>
          <Tabs.Tab value="rejected">Rejected</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="new">
          <div className="max-h-[62vh] overflow-y-auto ">{renderTable()}</div>
        </Tabs.Panel>

        <Tabs.Panel value="approved">
          <div className="max-h-[62vh] overflow-y-auto">{renderTable()}</div>
        </Tabs.Panel>

        <Tabs.Panel value="rejected">
          <div className="max-h-[62vh] overflow-y-auto">{renderTable()}</div>
        </Tabs.Panel>
      </Tabs>

      <Pagination paging={paging} page={page} setPage={setPage} />

      <Modal
        key={`${selectedRows.length}_k`}
        resetSteps={resetSteps}
        opened={opened}
        close={handleClose}
        open={open}
        approvalData={
          selectedTransaction
            ? [selectedTransaction]
            : approval.filter((transaction) => selectedRows.includes(transaction._id))
        }
        isApproval={isApproval}
        currentStep={selectedRows.length > 1 ? 2 : 1}
        setTab={setTab}
      />
    </>
  );
};

export default ApprovalsTab;
